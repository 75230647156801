<template>
    <div v-loading="loading">
        <!-- <h1>{{accountDetail.mail}}</h1> -->
        <div class="card card-custom card-stretch gutter-b">
            <div class="card-header">
                <div class="card-title">
                     <span class="card-icon">
                        <i class="flaticon2-delivery-package text-primary"></i>
                    </span>
                    <h3 class="card-label"><b class="title-header">アカウント詳細・編集</b></h3>
                </div>
            </div>
            <div class="card-body" style="height:500px;">
                <el-form ref="ruleForm" :model="detail_admin_user" label-width="30%" class="demo-ruleForm demo-form-edit"
                @keyup.enter.native="submit">

                    <div class="row mb-8">
                        <div class="col-12 col-md-10 col-xl-5">
                            <el-form-item label="メールアドレス" prop="mail" class="item-title-form item-fome-base form-item-start label-form-account">
                                <el-input class="bg-gray-item"
                                          maxlength="100"
                                        v-model="detail_admin_user.mail"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                     <div class="row mb-8">
                        <div class="col-12 col-md-10 col-xl-5">
                            <el-form-item label="ステータス" prop="user_status" class="item-title-form item-fome-base form-item-start label-form-account">
                                <el-select class="bg-gray-item" v-model="detail_admin_user.user_status" style="width:100%;" placeholder="なし">
                                    <el-option v-for="status in arrStatus"
                                            :label="status.label"
                                            :value="status.value"
                                            :key="status.value"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
<!--                    phase 2-->
                    <div class="row mb-8">
                        <div class="col-12 col-md-10 col-xl-5">
                            <label class="el-form-item__label" for=""> テナント </label>
                            <el-form-item class="item-title-form item-fome-base" label=""
                                          prop="tenant_code">
                                <el-select class="w-100 bg-gray-item" @change="changeTenant()" v-model="detail_admin_user.tenant_code" placeholder="選択してください">
                                  <el-option label="なし" value=" "></el-option>
                                    <el-option
                                        v-for="(item, index) in listTenant"
                                        :key="index"
                                        :label="item.tenant_name"
                                        :value="item.tenant_code">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>

                    <div class="row mb-8">
                        <div class="col-12 col-md-10 col-xl-5 custom-edit">
                            <el-form-item label="権限名" prop="role_id" class="item-title-form item-fome-base form-item-start label-form-account">
                                <el-select class="bg-gray-item" v-model="detail_admin_user.role_id" style="width:100%;" >
                                    <el-option v-for="item in listRole"
                                            :key="item.role_id"
                                            :label="item.role_name"
                                            :value="item.role_id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row mb-8">
                        <div class="col-12 col-md-10 col-xl-5">
                            <label class="el-form-item__label" for="">電話番号 <span
                                class="text-danger">*</span></label>
                            <el-form-item class="item-title-form item-fome-base" label="" prop="phone">
                                <el-input maxlength="100" class="bg-gray-item"  placeholder="電話番号を入力"
                                          v-model="detail_admin_user.phone"></el-input>
                            </el-form-item>
                        </div>
                    </div>
<!--                    phase 2-->
                    <el-form-item prop="action" class="button-search" label-width="0px"  style="position: absolute;right:0%; left:0%; box-sizing: border-box;" >
                        <div class="row">
                            <div class="col-6">
                                <el-button class="" type="primary" @click.prevent="submit" style="padding-left:4rem; padding-right:4rem;" >入力完了</el-button>
                            </div>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <Popup id="confirm" title="確認" :content="title" :title_button="close_popup">
            <b-button class="btn-primary" @click.prevent="check">{{$t("YES")}}</b-button>
        </Popup>
        <my-dialog v-on:closeDialog="not_permisson_popup=false" v-on:submit="not_permisson_popup=false;$router.push({name: 'permission-denied'});" v-bind:dialog="not_permisson_popup"
                   :closeOnClickModal="false" width="28%" v-bind:btnOk="$t('DIALOG.BTN_OKIE_PERMISSON_DENIED')" :showCancelButton="false">
            <template slot="header">
                <div class="textwidget text-center">
                    {{ $t('DIALOG.TITLE_PERMISSON_DENIED') }}
                </div>
            </template>
        </my-dialog>
    </div>
</template>

<style lang="scss" scoped>
.card-custom {
    border-radius: 15px;
}

.card-custom > .card-header {
    padding: 0;
    margin: 0px 21px;
}
</style>

<script>
import apiService from "@/core/services/api.service";
import * as constants from "@/core/config/constant";
import { mapGetters, mapState, mapActions } from "vuex";
import {DETAIL_ADMINUSER,UPDATE_ADMIN_USER} from "@/core/services/store/adminuser.module";
import {GET_ALL_TENANT} from "@/core/services/store/mtenant.module";
import Popup from "@/view/components/Popup";
import {
  GET_LIST_ROLE_NAME,
} from "@/core/services/store/approval.module";

export default {
  components: {Popup},
    data() {
        return {
            accountDetail:[],
            dialogFormVisible: false,
            dialogForm: false,
             ruleForm: {
                 mail: '',
                 user_status:'',
                 role_id: '',
                 tenant_code:'',
                 phone:'',
            },
            arrStatus: constants.USER_STATUS,
            message:'',
            close_popup: this.$t("NO"),
            title: '',
            loading: false,
            detail_admin_user:{},
            listRole:[],
            listTenant:[],
            not_permisson_popup: false,
        }
    },

    computed: {
        ...mapGetters(['detail_adminuser_new', 'listRoleName', 'currentUser']),
            queryParam() {
            return this.$route.params
        },
    },

    async created() {
        if (this.hasPermissonCheck('kengen_flg')){
            await this.getListRoleName();
            this.getListTenant();
            await this.detail_adminuser(this.queryParam.user_id);
            this.detail_admin_user = this.detail_adminuser_new;
            let checkRole = true;
            this.listRole = JSON.parse(JSON.stringify(this.listRoleName));
            for (let i = 0; i < this.listRoleName.length;i++){
                let thisRole = this.listRoleName[i];
                if (thisRole.role_id == this.detail_admin_user.role_id){
                    checkRole = false;
                }
            }
            if (checkRole){
                this.detail_admin_user.role_id = this.$t("ROLE_STATUS.UN_USED");
            }else{
                this.detail_admin_user.role_id = parseInt(this.detail_admin_user.role_id);
            }
            this.changeTenant();
            this.arrStatus = constants.ADMINUSER_STATUS
        }else {
            this.not_permisson_popup = true
        }

    },

    methods:{
        ...mapActions([DETAIL_ADMINUSER,UPDATE_ADMIN_USER, GET_LIST_ROLE_NAME,GET_ALL_TENANT]),
        dataDetailAdminUser(data){
            this.accountDetail = data
        },
        async getListTenant() {
            this.listTenant = await this.getAllTenant();
        },

        submit(){
            this.title = this.$t('CONFIRM_EDIT_ACCOUNT',{email: this.detail_adminuser_new.mail})
            this.$bvModal.show("confirm");
        },
        check(){
           this.$refs["ruleForm"].validate(async (valid) => {
               this.loading = true;
                this.turnOffErrorMessage("ruleForm");
                this.detail_adminuser_new.mail= this.detail_adminuser_new.mail.trim();
               if(valid){
                   console.log(this.detail_adminuser_new);
                    this.updateAdminUser({
                        user_id: this.detail_adminuser_new.user_id,
                        mail: this.detail_adminuser_new.mail,
                        user_status: this.detail_adminuser_new.user_status,
                        role_id: this.detail_adminuser_new.role_id,
                        tenant_code: this.detail_adminuser_new.tenant_code,
                        phone: this.detail_adminuser_new.phone,
                    })
                    .then((response) => {
                        if(response && response.data && response.data.data){
                            if (response.data.code === constants.STATUS_CODE.SUCCESS){
                            this.notifySuccess(this.$t("UPDATE_SUCCESS"));
                                let self = this;
                                setTimeout(function () {
                                    self.$router.push({
                                    name: "list-account",
                                    });
                                }, 500);
                            }else{
                                this.showErrorMessage(response.data.data, "ruleForm");
                                this.$bvModal.hide("confirm");
                                this.loading = false;
                            }
                    }else{
                        this.notifyError(this.$t("ERROR_SERVER"))
                        this.$bvModal.hide("confirm");
                        this.loading = false;
                    }
                    }).catch((e) => {
                        console.log(e)
                        this.notifyError(e);
                        this.$bvModal.hide("confirm");
                        this.loading = false;
                });
               }
           })
        },
        changeTenant(){
            this.listRole = this.listRoleName.filter(roleList => {
                if (roleList.tenant_code === this.detail_adminuser_new.tenant_code){
                    return true;
                }else if((this.detail_adminuser_new.tenant_code === ' ' || !this.detail_adminuser_new.tenant_code)
                    && !this.currentUser.super_user && this.currentUser.tenant_code === roleList.tenant_code){
                    return true;
                }else if((this.detail_adminuser_new.tenant_code === ' ' || !this.detail_adminuser_new.tenant_code) && this.currentUser.super_user && roleList.tenant_code === null){
                  return true;
                }
                return false;
            });
            let currentRole = this.listRole.find(role => role.role_id == this.detail_adminuser_new.role_id);
            if (!currentRole){
              this.detail_adminuser_new.role_id = '';
            }
        },
    }
}
</script>

